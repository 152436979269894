.account-page {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 57px;
  .account_page_heading{
    align-items: center;
  }

  .account_details {
    color: #505050;
    opacity: 1;
  }

  .green-color {
		color: #14A351;
  }

  .red-color {
    color: #E34A3D
  }

  .inactive-subscription-text{
    color:#C3C3C3 !important
  }
  
  .dot {
    height: 10px;
    width: 10px;
    background-color: #14A351;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .red-dot {
    height: 10px;
    width: 10px;
    background-color: #E34A3D;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .card_dots {
    font-weight: bold;
    font-size: 40px;
  }

  .details-heading {
    text-align: left;
    font-family: SF Pro Display, serif;
    font-size: 22px;
    letter-spacing: 0px;
    color: #505050;
    opacity: 1;
  }

  .details {
    text-align: left;
    font-family: SF Pro Display, serif;
    letter-spacing: 0px;
    color: #6C6C6C;
    opacity: 1;
  }

  .link {
    text-align: left;
    font-size: Medium;
    font-family: SF Pro Display, serif;
    letter-spacing: 0px;
    color: #50E3C2;
    opacity: 1;
  }

  .error-div {
    background: #FFE5E5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-size: 18px;
    font-family: SF Pro Display, serif;
    letter-spacing: 0px;
    color: #E34A3D;
    opacity: 1;
    height: 55px;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .account-page {

    .active-profile {
      font-size: 15px;
    }

    .dot {
      height: 10px;
      width: 10px;
    }

    .details-heading {
      font-size: 19px;
    }

    .details, .link {
      font-size: 13px;
    }

    img {
      height: 16px;
    }

    .card_dots {
      font-size: 13px;
    }
  }
}