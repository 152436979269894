.reset-password-container {
    .container-background-color {
        background-color: #d9dde1;
        min-height: 100vh;
        align-items: center;
    }

    .card-box-shadow {
        box-shadow: 0 1px 3px 0 #0000004f;
    }

    .title {
        font: bold 24px/26px SF Pro Display;
        color: #505050;
    }

    .sub-title {
        font: 17px/22px SF Pro Display;
        color: #505050;
    }

    .request-reset-button,
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:focus {
        color: white;
        background-color: #400077;
        border-color: #400077;
        height: 60px;
        box-shadow: inherit;
    }
    
    .back-login, .back-login:hover {
        color: #400077; 
        text-decoration: underline;
    }
    
    .form-control {
        height: 70px;
    }
    
    .btn-primary.disabled, .btn-primary:disabled {
        color: #7C68AF;
        cursor: not-allowed;
    }

    @media screen and (max-width: 768px){
        .form-control {
            height: 55px;
        }

        .request-reset-button {
            height: 50px;
            font: 12px/20px SF Pro Display;
        }
    }
}

.update-password-background {
    background-color: #d9dde1;
}