.zenevate_navbar {
  background: $purple 0% 0% no-repeat padding-box;
  height: 65px;
}

nav.navbar {
  background: $purple;
}

.zenevate_logo {
  height: 40px;
}
.font-weight-100{
  font-weight: 100 !important;
}
.nav_link {
  font: bold 0.9rem / 1.2rem SF Pro Display;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  text-transform: uppercase;
  opacity: 1;
}

.nav_username {
  font: bold 1rem / 1.2rem SF Pro Display;
  letter-spacing: 0.5px;
  color: #FFFFFF;
  text-transform: none;
  a {
    color: #FFFFFF !important;
  }
  .dropdown-menu {
    overflow: hidden;
  }
  .dropdown-item {
    color: $grey !important;
    line-height: 28px;
  }
  .dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: $purple;
  }
  .dropdown-item:focus {
    border: none !important;
    outline: none;
  }
}

header .nav-item {
  padding: 0.9em;
}

@media only screen and (max-width: 768px) {
  nav .navbar-brand {
    padding-left: 0rem;
  }
}
@media (min-width: 992px){
  .nav_link {
    margin-left : 1em;
    margin-right : 1em;
  }
}

.navbar-light span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  color: white;
}

.color-green{
  color: $light_green !important;
  a{color: $light_green !important;}
}

.nav-bar-toggle-button:focus {
  outline: none !important;
}