.journey-heading {
  font: 23px/38px SF Pro Display;
  letter-spacing: 0px;
  color: #505050;
  opacity: 1;
}

.journey-btn {
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  width: 147px;
  height: auto;
}

.journey-btn-all-time {
  border-radius: 0px 0px 0px 5px;
}

.journey-btn-week {
  border-radius: 5px 0px 0px 0px;
}

.journey-btn-active {
  background: #50E3C2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  font: Bold 14px/20px SF Pro Display;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.journey-btn-inactive {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000026;
  text-align: center;
  font: 14px/20px SF Pro Display;
  letter-spacing: 0px;
  color: #747474;
  opacity: 0.75;
}

.yoga-col {
  background: #F5F5F5 0% 0% no-repeat padding-box;

  .row {
    width: 100%;
  }
}
.yoga-icon {
  background: #50E3C2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  height: 200px;
  z-index: 10;

  img {
    height: 130px;
  }
}

.video-stats {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 0px 5px;
  opacity: 1;
  z-index: 1;
  display: flex;
}

.stats-section{
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: -4px;
}

.reflect p {
  font: 3.125rem / 3.75rem SF Pro Display Thin;
  font-weight: lighter;
  letter-spacing: 0px;
  color: #C3C3C3;
  opacity: 1;
}

.stats {
  // font: Bold 30px/39px SF Pro Display;
  letter-spacing: 0px;
  color: #A5A5A5 !important;

  span {
    // font: 25px/39px SF Pro Display;
    color: #50E3C2;
  }
}

.h-divider {
  margin-top:5px;
  margin-bottom:5px;
  height:1px;
  width:100%;
  border-top:1px solid #e8e8e8;
}

.reflect {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 0px 5px 5px 0px;

  p {
    padding-top: 4rem;
  }
}

.video_history {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 0px 5px 5px 0px;
  opacity: 1;

  .video-date {
    font: Bold 16px/26px SF Pro Display;
    letter-spacing: 0px;
    color: #606060;
  }
}

.dotted {
  border-right: 3px dashed #A5A5A5;
  opacity: 1;
}

.journey_video {
  p, h4 {
    margin-bottom: 0px;
  }

  .card-video-title{
    -webkit-line-clamp: 2;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2 !important;
    color: $purple;
  }

  .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 10px 10px 10px;
    border: none;
  }
  .card-video {
    height: 100%;
    max-height: 140px;
  }
  .card-title {
    font: 10px/15px SF Pro Display;
    letter-spacing: 0px;
    color: #505050;
  }

  .video-title {
    font: 17px/16px SF Pro Display;
    color: #400077;
    letter-spacing: 0px;
  }

  .card-yoga-type {
    font: 10px/15px SF Pro Display;
    letter-spacing: 0px;
    color: #50E3C2;
  }

  .journey-card-video-duration{
    color: #50e3c2;
    font-family: SF Pro Display;
    font-size: 14px;
    text-align: center;
  }

  .journey-card-video-level {
    color: #50e3c2;
    font-family: SF Pro Display;
    font-size: 10px;
    text-align: center;
  }

  .video-content-right {
    text-align: center;
    align-items: center;
    border-left: 1px solid #efefef;
}
}

.v-card:hover{
  transform: scale(1.02);
  cursor: pointer;
}

.v-card{
  transition-duration: 1s;

  .card-video-level-image {
    height: 10px;
  }
}
@media only screen and (max-width: 1000px){
  .journey_video .card-video {
    max-height: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .card {
    max-width: 355px;
    margin: auto;
   }

  .journey-btn-all-time {
    border-radius: 0px 5px 0px 0px;
  }

  .journey-btn-week {
    border-radius: 5px 0px 0px 0px;
  }

  .yoga-icon {
    width: 94px;
    height: 88px;

    img {
      height: 65px;
    }
  }
  .reflect{
      border-radius: 5px 5px 0px 0px;
      p {
      padding-top: 0;
      font: 2.125rem / 3.75rem SF Pro Display Thin;
    }
  }
  .yoga-col > .row{
    width: auto;
  }

  .journey_video {
    .card-video {
      height: unset;
      max-height: unset;
    }

    .card-video-title{
      font: 15px/15px SF Pro Display;
    }
  }

  .video-stats {
    .row {
      width: 105%;
    }
  }
}