.modal-message{
    color: grey;
  }
  .modal-btn-purple{
    color: $white;
    background-color:$purple;
    border-color: $purple;
  }
  .modal-btn-purple:hover{
      color: $white;
      background-color:$purple;
      border-color: $purple;
  }
  .modal-btn-purple:active {
    color: $white !important;
    background-color:$purple !important;
    border-color: $purple !important;
  }
  .modal-btn-purple:focus{
    background-color:$purple !important;
    border-color: $purple !important;
    box-shadow: unset !important;
  }
  .modal-btn-red{
    color: $white;
    background-color: $red;
    border-color: $red;
  }
  .modal-btn-red:hover{
      color: $white;
      background-color: $red;
      border-color: $red;
  }