.search-content {

	.videos{
		margin-right: unset;
		margin-left: unset;
	}

	.card-img-overlay {
		background-image: url('../../images/play.svg');
		background-repeat: no-repeat;
		background-position: 50% 30%;
	}
	.card-instructor-name {
		color: #505050;
		font-size: 12px;
		letter-spacing: 0;
		font-family: SF Pro Display;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.current-month-videos {
		background: #f5f5f5 0% 0% no-repeat padding-box;
		border-radius: 10px;
	}

	.card-video-yoga-style {
		font-family: SF Pro Display;
		font-size: 12px;
		color: #50e3c2;
	}

	.search-container {
		margin-top: 0;
		background: #f5f5f5 0% 0% no-repeat padding-box;
		opacity: 1;
		margin-left: unset;
		margin-right: unset;
	}

	.search-container-no-bg {
		margin-top: 0;
		margin-left: unset;
		margin-right: unset;
	}

	.search-input {
		outline: none;
		background: transparent;
		color: #a5a5a5;
		letter-spacing: 0px;
		text-align: left;
	}

	.search-input::placeholder {
		color: #a5a5a5;
		letter-spacing: 0px;
		text-align: left;
	}
	.search-input:focus {
		border-color: inherit;
		-webkit-box-shadow: none;
		box-shadow: none;
		background: transparent;
	}

	.search-input-group {
		border-bottom: 1px solid #c3c3c3;
		opacity: 1;
	}

	.card-box,
	.card-img-overlay {
		transition: all 1s ease;
	}

	.card-box:hover,
	.card-img-overlay:hover {
		transform: scale(1.05);
		cursor: pointer;
	}

	.card-video-title {
		line-height: 1.2 !important;
		color: #400077;
		letter-spacing: 0;
		font-family: SF Pro Display;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.card-video-level {
		color: #50e3c2;
		font-family: SF Pro Display;
		font-size: 11px;
		text-align: center;
		margin-top: auto;
		margin-bottom: auto;
	}

	.video-card {
		box-shadow: 0px 3px 6px #00000029;
	}

	.card-video-level-text {
		padding-left: 5px;
	}

	.card-video-level-image {
		width: 10px;
		height: 10px;
	}

	.card-video-duration {
		color: #50e3c2;
		font-family: SF Pro Display;
		font-size: 12px;
		word-spacing: normal;
		text-align: center;
	}
	.video-content-right {
		text-align: center;
		align-items: center;
		border-left: 1px solid #efefef;
		padding: 10px;
	}

	hr {
		margin-top: 1rem;
		margin-bottom: 1rem;
		margin-left: 5px;
		border: 0;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		width: 100%;
	}

	.search-content-heading {
		color: #505050;
		font-size: 20px;
		font-family: SF Pro Display;
		margin-left: 10px;
	}

	.card-box {
		margin: 5%;
	}
	.filterDropDown {
		width: 100%;
		height: 40px;
		text-align: left;
		letter-spacing: 0;
		color: #606060;
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 37px;
	}

	.filterDropDown::after {
		float: right;
		margin-top: 10px;
	}

	.dropdown-menu {
		border: none;
	}

	.dropdown-item:active, .dropdown-item:focus {
		color: #fff;
		text-decoration: none;
		background-color: #400077;
	}

	.custom-checkbox
		.custom-control-input:checked
		~ .custom-control-label::before {
		background-color: #400077;
		border: none;
	}
	.custom-checkbox .custom-control-label::before {
		height: 15px;
		width: 15px;
	}

	.dropdown-row {
		display: flex;
	}

	.custom-control-label {
		font-family: SF Pro Display;
		font-size: 14px;
		padding-top: 2px;
	}

	.filter-error {
		padding-top: 2px;
		padding-left: 10px;
	}

	.search-icon {
		width: 34px;
		height: 34px;
	}

	.clear-filter{
		color: $purple;
		font-weight: bold;
		cursor: pointer;
	}

	@media screen and (max-width: 1024px){
		.filterDropDown {
			font-size: 10px;
		}
	}
	@media (min-width: 768px) {
		.card-video-level {
			color: #50e3c2;
			font-family: SF Pro Display;
			font-size: 10px;
			text-align: center;
			margin-top: auto;
			margin-bottom: auto;
		}
		.card-video-duration {
			font-size: 16px;
		}
	}

	@media (max-width: 768px) {
		.filterDropDown {
			font-size: 10px;
		}

		.filterDropDown::after {
			margin-top: 7px;
		}
		
		.sub-menu-toggle {
			width: 100%;
			padding-top: -5px;
			padding-right: 10px;
			height: 35px;
			text-align: left;
			font-size: 14px;
			font-family: SF Pro Display;
			font-weight: normal;
			letter-spacing: 0;
			background: transparent;
			border: none;
		}
		.card-video-duration {
			font-size: 12px;
		}

		.search-input::placeholder {
			color: #a5a5a5;
			letter-spacing: 0px;
			text-align: left;
		}
		.card-video-level {
			color: #50e3c2;
			font-family: SF Pro Display;
			font-size: 8px;
			text-align: center;
			margin-top: auto;
			margin-bottom: auto;
		}

		.sub-menu-toggle::after {
			float: right;
			margin-top: 10px;
		}

		.dropdown-submenu {
			position: relative;
		}
		.dropdown-submenu > .dropdown-menu {
			top: 0;
			left: 100%;
			margin-top: 0px;
			margin-left: 0px;
		}

		.dropdown-submenu:hover > .dropdown-menu {
			display: block;
		}

		.mobile-filters {
			width: 100%;
		}
	}
	@media (max-width: 375px) {
		.card-video-duration {
			font-size: 16px;
		}

		.card-video-level {
			font-size: 11px;
		}
		.search-input::placeholder {
			color: #a5a5a5;
			letter-spacing: 0px;
			text-align: left;
		}
		.search-icon {
			width: 24px;
		}
	}
}
