.no-active-subscription {
	background: #ffe5e5 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;

	.content {
        color: #e34a3d;
	}

	.subscribe-button {
        font-family: 'SF Pro Display';
		background: $purple 0% 0% no-repeat padding-box;
		border-radius: 10px;
        opacity: 1;
        border: none;
    }
    
    .subscribe-button::focus {
        border: none;
    }

	.danger-icon {
		width: 30px;
    }
    
	@media only screen and (max-width: 425px) {
		.content-div {
			margin: 10px;
            text-align: center;
        }
	}
}
