@import "~bootstrap/scss/bootstrap.scss";
@import "components/color_helpers.scss";
@import "components/nav_bar.scss";
@import "components/login_page.scss";
@import "components/video_details_page.scss";
@import "components/account_page.scss";
@import "components/content.scss";
@import "components/my_journey.scss";
@import "components/reset_password.scss";
@import "components/video_player.scss";
@import "components/footer.scss";
@import "components/instructor_details_page.scss";
@import "components/no_active_subscription.scss";
@import "components/modal.scss";

@font-face {
  font-family: "SF Pro Display";
  font-weight: normal;
  src: local("SF Pro Display"),
    url("../fonts/SF Pro Display-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Display";
  font-weight: bold;
  src: local("SF Pro Display"),
    url("../fonts/SF Pro Display-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Display Thin";
  src: local("SF Pro Display Thin"),
    url("../fonts/SF Pro Display-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Display Medium";
  src: local("SF Pro Display Medium"),
    url("../fonts/SF Pro Display-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Cuprum";
  font-weight: normal;
  src: local("Cuprum"), url("../fonts/Cuprum-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cuprum";
  font-weight: bold;
  src: local("Cuprum"), url("../fonts/Cuprum-Bold.ttf") format("truetype");
}

.min-height {
  min-height: calc(54vh - 70px);
}

.custom-control {
  padding-left: 0;
}

.custom-switch {
  padding-left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.pageLoading {
  height: 100%;
  width: 100%;
  top: 0px;
  position: fixed;
  background: rgba(162, 140, 124, 0.23);
  z-index: 4999;
  display: none;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.font-size-1 {
  font-size: 27px !important;
  line-height: 36px !important;
}

.font-size-2 {
  font-size: 21px !important;
  line-height: 29px !important;
}

.font-size-3 {
  font-size: 20px !important;
  line-height: 24px !important;
}

.font-size-4 {
  font-size: 17px !important;
  line-height: 24px !important;
}

.font-size-5 {
  font-size: 16px !important;
  line-height: 22px !important;
}

.font-size-6 {
  font-size: 14px !important;
  line-height: 22px !important;
}

.heading-1 {
  @extend .font-size-1;
  font-family: "SF Pro Display Medium" !important;
  color: #333333;
}

.heading-1-regular {
  @extend .font-size-1;
  font-family: "SF Pro Display" !important;
  color: #333333;
}

.heading-2 {
  @extend .font-size-2;
  font-family: "SF Pro Display" !important;
  color: #333333;
}

.heading-3 {
  @extend .font-size-3;
  font-family: "SF Pro Display Medium" !important;
  color: #333333;
}

.heading-4 {
  @extend .font-size-4;
  font-family: "SF Pro Display" !important;
  color: #505050;
}

.heading-5 {
  @extend .font-size-5;
  font-family: "SF Pro Display" !important;
}

.heading-6 {
  @extend .font-size-6;
  font-family: "SF Pro Display" !important;
}

@media screen and (max-width: 768px) {
  .heading-1 {
    font-size: 24px !important;
    line-height: 30px !important;
    color: #333333;
  }

  .heading-1-regular {
    font-size: 24px !important;
    line-height: 30px !important;
    color: #333333;
  }

  .heading-2 {
    font-size: 18px !important;
    line-height: 23px !important;
    color: #333333;
  }

  .heading-3 {
    font-size: 16px !important;
    line-height: 18px !important;
    color: #333333;
  }

  .heading-4 {
    font-size: 16px !important;
    line-height: 18px !important;
    color: #505050;
  }
  .heading-5 {
    font-size: 14px !important;
    line-height: 18px;
  }
}

@media screen and (max-width: 425px) {
  .heading-1 {
    font-size: 24px !important;
    line-height: 30px !important;
    color: #333333;
  }

  .heading-1-regular {
    font-size: 24px !important;
    line-height: 30px !important;
    color: #333333;
  }

  .heading-2 {
    font-size: 18px !important;
    line-height: 23px !important;
    color: #333333;
  }

  .heading-3 {
    font-size: 16px !important;
    line-height: 18px !important;
    color: #333333;
  }

  .heading-4 {
    font-size: 16px !important;
    line-height: 18px !important;
    color: #505050;
  }
}
