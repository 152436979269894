.StripeElement {
	box-sizing: border-box;

	height: 40px;

	padding: 10px 12px;

	border: 1px solid transparent;
	border-radius: 4px;
	background-color: white;

	box-shadow: 0 1px 3px 0 #e6ebf1;
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}

.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.card-errors {
	color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

.update-card-heading {
	color: #505050;
	font-size: large;
}
.add-card-button:visited {
	pointer-events: none;
	cursor: default;
	opacity: 0.6;
}

.backdrop-image.modal.show{
	background-image: url('../../images/powered_by_stripe.svg');
	background-repeat: no-repeat;
	background-size: 200px;
	background-position: left bottom;
}

.cancel-subscription-heading {
	font-size: large;
	color: #505050
}

.cancel-subscription-content {
	font-size: medium;
	color: #505050;
}

