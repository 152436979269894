.instructor-details-page {
    .top-banner {
        position: relative;
        text-align: center;
        color: #FFFFFF;
        margin-top: 4%;

    }

    .top-banner::after {
        display: block;
        position: relative;
        background:  #3a3a3a62;
        margin-top: -700px;
        height: 700px;
        width: 100%;
        content: '';
        z-index: 2;
    }

    .banner-text {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
        color: #FFFFFF;
        z-index: 5;
        
        .banner-heading {
            font: 56px/50px SF Pro Display Medium;
        }
        .banner-sub-heading {
            font: 33px/38px SF Pro Display;
        }
    }

    .breadcrumb {
		background-color: unset;
		padding-left: 0;
        padding-top: 0;
        margin-top: -0.2rem;
	}

	.breadcrumb-item + .breadcrumb-item::before {
		content: '>';
    }
    
    .breadcrumb-item {
		font: 16px/19px SF Pro Display;
        line-height: 24px;
		a {
			color: #9d9d9d;
		}
	}
    
    .breadcrumb-item.active {
		color: #505050 !important;
	}

	.breadcrumb-active-color {
		color: #505050;
	}

    .instructor-detail-box {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 0px 5px 5px 0px;
    }

    .profile-image {
        width: 100%;
        object-fit: cover;
        height: 360px;
    }

    .style-image {
        width: 15%;
    }

    .yogaStyle-alignment {
        margin-top: auto;
        margin-bottom: auto;
    }

    .certification-image {
        width: 12%;
    }

    .pin-style {
        width: 2%;
    }

    a:last-child {
		margin-right: 0px !important;
	}
    
    .certification-image:last-child {
        margin-right: 0px !important;
    }

    @media screen and (max-width:1200px){
        .top-banner {
            margin-top: 8%;
        }
        
        .style-image {
            width: 20%;
        }
        
        .profile-image {
            width: 110%;
            object-fit: cover;
            height: 280px;
        }
    }
    
    @media screen and (max-width:1024px){
        .profile-image {
            width: 110%;
            object-fit: cover;
            height: 260px;
        }
    }
    

    @media only screen and (max-width: 768px) {
		.breadcrumb-item {
			font: 14px/17px SF Pro Display;
        }

        .social-link-image {
			height: 32px;
			width: 32px;
        }

        .banner-text {
            .banner-heading {
                font: 40px/20px SF Pro Display Medium;
            }
            .banner-sub-heading {
                margin-top: 5%;
                font: 24px/30px SF Pro Display;
            }
        }

        
        .pin-style {
            width: 3%;
        }
        
        .top-banner {
            margin-top: 10%;
        }
        
        .certification-image {
            width: 15%;
        }

        .profile-image {
            width: 110%;
            object-fit: cover;
            height: 240px;
        }
        
        
    }
    @media only screen and (max-width: 767px) {
        
        .profile-image {
            width: 75%;
            object-fit: cover;
            height: 400px;
        }
        
        .style-image {
            width: 5%;
        }

        .mobile-top-margin {
            margin-top: 25%;
        }
    }

    @media only screen and (max-width: 425px) {
        .social-link-image {
			width: 75%;
        }
        
        .style-image {
            width: 10%;
        }

        .social-link-position {
            display: inline-block;
        }

        .pin-style {
            width: 5%;
        }

        .profile-image {
            width: 100%;
            object-fit: cover;
            height: 360px;
        }

        .mobile-top-margin {
            margin-top: 35%;
        }

        .certification-image {
            width: 17%;
        }
    }

    @media only screen and (max-width: 375px) {
        .profile-image {
            width: 100%;
            object-fit: cover;
            height: 350px;
        }
    }

    @media only screen and (max-width: 325px) {
        .mobile-top-margin {
            margin-top: 45%;
        } 

        .profile-image {
            width: 100%;
            object-fit: cover;
            height: 290px;
        }
    }
}