.video-detail-container {
	.breadcrumb {
		background-color: unset;
		padding-left: 0;
		padding-top: 0;
		margin-top: -0.2rem;
	}

	.breadcrumb-item + .breadcrumb-item::before {
		content: '>';
	}

	.breadcrumb-item {
		font: 16px/19px SF Pro Display;
		line-height: 24px;
		a {
			color: #9d9d9d;
		}
	}

	.r-image {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	.breadcrumb-item.active {
		color: #505050 !important;
	}

	.breadcrumb-active-color {
		color: #505050;
	}

	.ocean-green-color {
		color: #50e3c2;
	}

	.practice-button,
	.practice-button:hover,
	.btn-primary:not(:disabled):not(.disabled):active,
	.btn-primary:focus, .btn-primary.focus {
		background: #400077 0% 0% no-repeat padding-box;
		font: 20px/28px SF Pro Display Medium;
		border-radius: 10px;
		width: 40%;
		height: 55px;
		border-color: inherit;
		box-shadow: inherit;
		transition: all .5s;

		&:active {
			background-color: rgb(107, 65, 170);
		}
	}

	.instructor-summary {
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 0px;
	}

	.video-title {
		color: #333333;
		padding-left: 0;
		margin-top: 2%;
	}

	.instructor-detail {
		border-top: 1px solid #dbdbdb;
	}

	.video-duration {
		color: #50e3c2;
	}

	.know-more {
		color: #400077 !important;
	}

	.props-row {
		border-top: 1px solid #dbdbdb;
		padding-top: 3%;
		padding-bottom: 3%;
	}

	.border-right {
		border-right: 1px solid #dbdbdb;
	}

	.about-body {
		padding-top: 5%;
	}

	.play-thumbnail {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 15%;
	}

	.level-row {
		color: #50e3c2;

		.img {
			width: 4%;
		}
	}

	.about-description {
		white-space: pre-wrap;
		margin-top: 3%;
	}

	.video-thumbnail {
		position: relative;
		height: auto;
		transition: all .5s;

		&:active {
			background-color: rgb(107, 65, 170);
		}
	}

	.video-summary {
		justify-content: space-between;
	}

	.instructor {
		display: inline-block;
		vertical-align: middle;
	}

	a:last-child {
		margin-right: 0px !important;
	}

	.title-body {
		padding-left: 5%;
	}

	.instructor-detail-mobile {
		border-top: 1px solid #dbdbdb;
		text-align: -webkit-center;
	}

	.justify-space-between {
		justify-content: space-between;
	}

	.lotus-image {
		width: 25%;
		display: inline-block;
	}

	.inline-block {
		display: inline-block;
	}

	.icon-width {
		width: 32px;
		height: 32px;
		margin-top: 5px;
	}

	.avatar-size {
		width: 80px;
		height: 80px;
		border-radius: 100%;
	}

	.border-top {
		border-top: 1px solid #dbdbdb;
	}

	@media only screen and (max-width: 1200px) {
		.practice-button,
		.practice-button:hover,
		.btn-primary:not(:disabled):not(.disabled):active,
		.btn-primary:focus, .btn-primary.focus {
			width: 53%;
			transition: all .5s;

			&:active {
				background-color: rgb(107, 65, 170);
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.breadcrumb-item {
			font: 14px/17px SF Pro Display;
		}
		.practice-button,
		.practice-button:hover,
		.btn-primary:not(:disabled):not(.disabled):active,
		.btn-primary:focus, .btn-primary.focus {
			width: 60%;
    		height: 45px;
			font: 16px/ 28px SF Pro Display Medium;
			transition: all .5s;

			&:active {
				background-color: rgb(107, 65, 170);
			}
		}

		.social-link-image {
			height: 32px;
			width: 32px;
		}

		.instructor-summary {
			text-align: left;
			margin-top: 10px;
		}

		.level-row {
			margin-top: 2%;
		}

		.avatar-size {
			width: 60px;
			height: 60px;
			border-radius: 80%;
		}

	}
	
	@media only screen and (max-width: 767px) {
		
		.title-body {
			padding-left: 15px;
		}
	}

	@media only screen and (max-width: 425px) {
		.practice-button,
		.practice-button:hover,
		.btn-primary:not(:disabled):not(.disabled):active,
		.btn-primary:focus, .btn-primary.focus {
			width: 70%;
			height: 50px;
			transition: all .5s;

			&:active {
				background-color: rgb(107, 65, 170);
			}
		}

		.level-row {
			margin-top: 5%;
		}

		.social-link-image {
			width: 75%;
		}

		.avatar-size {
			width: 60px;
			height: 60px;
			border-radius: 60%;
		}

		.know-more {
			margin-left: 15px;
		}
	}
}