.player-wrapper {
    position: relative;
    padding-top: 46.15%;
    width: 100%;
    height: 100vh;
    background-color: black;
}
  
.react-player {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2147483005;
  transform: translateY(-50%);
  background-color: black;
}

.close-button {
  position: absolute;
  z-index: 2147483010;
  top: 15px;
  right: 3%;
  height: 50px;
  width: 50px;
}