.zenevate-footer {
    width: 100%;
    background: #400077 0% 0% no-repeat padding-box;
    color: white;
    background-image: url('../../images/lotus-footer.svg');
    background-position-x: 108%;
    background-position-y: 160%;
    background-size: 24% 86%;
    
  .zenevate-logo {
    height: 30px;
  }
  .social-logo {
    height: 24px;
  }

  .mission-title{
    text-align: left;
    font: Bold 20px/29px Libre Baskerville;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .mission-description{
    text-align: left;
    font:  16px/24px Libre Baskerville;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .footer-link{
    font: 0.8em/24px SF Pro Display;
    letter-spacing: 0px;
    color: #FFFFFF;
    a{
    color: #FFFFFF;
    }
    a:hover {
      text-decoration: none;
    }
    text-transform: uppercase;
    opacity: 1;
    border-left: 1px solid #dee2e6;
    text-align: center;
    font-weight: 600; 
    cursor: pointer;
  }
  .footer-contact{
    cursor: pointer;
      a,p{
        font: Medium 0.8em/24px SF Pro Display;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-bottom: 0em; 
      }
      a:hover {
        text-decoration: none;
      }
  }
  .mobile-view{
    display: none;
  }
  .copyright{
      font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
    .zenevate-footer {
        background-position-x: 116%;
        background-position-y: 110%;
        background-size: 63% 32%;
        .mission-title{
            text-align: center;
        }
        .mission-description{
            text-align: center;
        }
        .mobile-view-none{
            display: none;
        }
        .mobile-view{
            display: block;
        }
        .zenevate-logo {
            height: 22px;
        }
        .social-logo {
            height: 22px;
        }
      }
}

@media only screen and (max-width:1199px) and (min-width: 1000px) {
  .md-lg-align-end{
    display: flex;
    justify-content: center;
  }
}