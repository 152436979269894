.login-page {
  background-image: url("../../images/login_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .container {
    max-width: inherit;
    max-height: inherit;
  }
  .card {
    background: #ffffffa7 0 0 no-repeat padding-box !important;
    opacity: 1;
    border-radius: 7px 7px 15px 15px !important;
    text-align: left;
    margin-top: 20%;

    .card-body {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      button {
        background: #400077 0 0 no-repeat padding-box;
        border-radius: 0 0 15px 15px;
        border-color: #400077;
        opacity: 1;
        width: 100%;
        height: 4.3rem !important;
        font-weight: bold;
        font-family: SF Pro Display;
      }

      button:active,
      button:focus {
        background: #400077 0 0 no-repeat padding-box;
        border-color: #400077;
        box-shadow: none;
        border-radius: 0 0 15px 15px;
        width: 100%;
        height: 4.3rem !important;
      }

      .btn:focus,
      .btn:active {
        box-shadow: none;
      }
    }
  }

  .login-form {
    input {
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #d3d7db;
      border-radius: 7px;
      opacity: 1;
      width: 89%;
      height: 110%;
    }

    .form-group {
      padding-left: 11%;
      padding-top: 3%;
      padding-bottom: 3%;
      margin: 0;

      .form-control {
        font-family: SF Pro Display;
        color: #a5a5a5;
        height: 4rem;
      }
    }
  }

  .login-error {
    padding-left: 11%;
    padding-right: 11%;
  }

  .sign-in-label {
    text-align: left;
    font-weight: bold;
    font-family: SF Pro Display !important;
    color: #242424;
    opacity: 1;
    padding-left: 11%;
  }

  .not-registered {
    text-align: left;
    font-family: SF Pro Display !important;
    color: #242424;
    padding-left: 11%;
    .card-link {
      font-weight: bold;
      font-family: SF Pro Display !important;
      color: #400077;
    }
  }

  .forgot-password {
    padding-right: 10%;
    padding-top: 0% !important;
    padding-bottom: 10% !important;
    text-align: right;

    .form-label {
      font-weight: bold;
      font-family: SF Pro Display !important;
      color: #400077;
    }
  }

  .logo-height {
    height: 48px;
  }

  @media only screen and (min-width: 769px) {
    .logo {
      padding-top: 59px;
      width: 15%;
    }
  }
  @media only screen and (max-width: 900px) {
    .logo {
      text-align: center;
      margin-top: 20%;
    }
    .card {
      margin-top: 20%;
      width: 100%;
    }
  }

  @media only screen and (min-width: 901px) {
    .card {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (min-width: 1200px) {
    .card {
      margin-left: 0%;
    }
  }

  @media only screen and (min-width: 1500px) {
    .card {
      width: 60%;
    }
  }

  @media only screen and (max-width: 375px) {
    .login-page .card .card-body button {
      border-radius: 0 0 10px 10px;
      height: 3.5rem !important;
    }

    .login-form .form-group .form-control {
      height: 3rem;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .login-page .logo {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 375px) {
  .login-page {
    background-position: inherit;
  }
}

@media only screen and (max-width: 425px) {
  .login-page {
    background-position: inherit;
  }
}
